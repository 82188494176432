import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import ReactDOM from "react-dom/client";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { BrowserRouter, Routes, Route } from "react-router-dom";

const theme = createTheme({
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1200
    }
  }
});

function App() {
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>

          <Routes>
            <Route path="/" element={<Home />} />








          </Routes>

        </BrowserRouter>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
